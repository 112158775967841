import { inject } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import {
  ActivatedRouteSnapshot,
  Resolve,
  RouterStateSnapshot,
} from "@angular/router";
import { MessageDialog } from "@shared/message-dialog.component";
import { BehaviorSubject, Observable, of } from "rxjs";
import { shareReplay, switchMap, tap } from "rxjs/operators";

/**
 * Cache Service is an observables based in-memory cache implementation
 * Keeps track of in-flight observables and sets a default expiry for cached values
 * @export
 * @class CacheService
 */
export abstract class AbstractCacheService<T, I> implements Resolve<T> {
  public cache: T[] = [];
  isLoading = true;
  abstract requestAll(): Observable<T[]>;
  abstract requestOne(id: I): Observable<T>;
  private cache$: Observable<Array<T>>;

  private refresh$ = new BehaviorSubject<void>(null);

  get all(): Observable<T[]> {
    if (!this.cache$) {
      this.cache$ = this.refresh$.pipe(
        switchMap((_) => this.requestAll()),
        tap((c) => {
          this.cache = c;
          this.isLoading = false;
        }),
        shareReplay(1)
      );
    }

    return this.cache$;
  }

  get(id: any): Observable<T> {
    // check if it is in the current observable.
    var r = this.cache && this.cache.filter((d) => d["id"] == id)[0];
    // if it is, just filter and return it
    if (r) return of(r);
    // if it isn't, request it from the server, and add it to the current cache?
    return this.requestOne(id).pipe(shareReplay(1));
  }

  forceReload() {
    // calling next here will complete the current cache instance

    this.isLoading = true;
    this.refresh$.next(null);
  }

  /// Make it as a resolver
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> | Promise<any> | any {
    return this.all;
  }

  readonly dialog = inject(MatDialog);
  confirm(
    firsttext: string,
    secondtext: string,
    callback: (r: boolean) => void
  ) {
    this.dialog
      .open(MessageDialog, {
        data: { maintext: firsttext, subtext: secondtext },
      })
      .afterClosed()
      .subscribe(callback);
  }
}
